import React from 'react';
import '../footers/Footer.css';

function MiddleHome() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Cladding
                </p>

                <p className='footer-subscription-text'>
                    We offer cladding on residential and commercial premises. Our team have the skills and experience necessary to provide you with top-quality workmanship and excellent customer service.
                    <br></br>
                    <br></br>
                    RESIDENTIAL CLADDING
                    <br></br>
                    <br></br>
                    We have a great deal of experience in the installation of soffits, facias and dry verge on domestic properties from small to large projects.
                    <br></br>
                    <br></br>
                    <img src={require('../images/facia_soffit_res.jpg')} width="367px" height="275" alt='facia_soffit_res' title="facia_soffit_res" loading="eager"/>    
                    <br></br>
                    <br></br>        
                    SIBERIAN LARCH
                    <br></br>
                    <br></br>        
                    We have good relations with our supplier of siberian larch in the Highlands and are able to competitively carry out the installation of this up and coming product. We can provide expert advice on the best option for your property.
                    <br></br>
                    <br></br>
                    <img src={require('../images/siberian_larch.jpg')} width="367px" height="275" alt='siberian_larch' title="siberian_larch" loading="eager"/>    
                    <br></br>
                    <br></br>        
                    COMMERCIAL AND INDUSTRIAL CLADDING
                    <br></br>
                    <br></br>        
                    We have a long history of repairing and installing commercial and industrial cladding particularly for oil related business in the NE Scotland. We are able to provide this service across Scotland and are fully insured for all premises.
                    <br></br>
                    <br></br>
                    <img src={require('../images/commercial_cladding.jpg')} width="367px" height="275" alt='commercial_cladding' title="commercial_cladding" loading="eager"/>    
                    <br></br>
                    <br></br>
                    <img src={require('../images/metal_sheeting.png')} width="367px" height="275" alt='metal_sheeting' title="metal_sheeting" loading="eager"/>    
                    <br></br>
                    <br></br>
                    Contact us today to schedule your appointment and receive a free estimate. We look forward to hearing from you.
                </p>
            </section>
        </div>
    );
}

export default MiddleHome;



