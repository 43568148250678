import React from 'react';

import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink
} from './footerElements';

const Footer = () => {

    return (
        <FooterContainer id = 'footer'>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>

                            <p>TUNN SLATING AND FLAT ROOFING</p>
                            <p>42F Don Street</p>
                            <p>Old Aberdeen</p>
                            <p>Aberdeen</p>
                            <p>United Kingdom</p>
                            <p>AB24 1UU</p>
                            <p>07394 387027</p>
                            <p>roberttunn@outlook.com</p>
                            <br></br>
                            <br></br>

                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
            </FooterWrap>
        </FooterContainer>
    );
};

export default Footer

