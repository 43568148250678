import React from 'react';
import '../footers/Footer.css';

function MiddleHome() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Flat Roofing
                </p>

                <p className='footer-subscription-text'>
                    We offer a wide range of flat roofing services to our customers. Our team have the skills and experience necessary to provide you with top-quality workmanship and excellent customer service.
                    <br></br>
                    <br></br>
                    <img src={require('../images/flat_roof_inst.jpg')} width="367px" height="275" alt='flat_roof_inst' title="flat_roof_inst" loading="eager"/>    
                    <br></br>
                    <br></br>
                    We are insured for all flat roofing activities on residential and commercial properties. Our services include new installations, repairs, and replacements for all types of flat roofs, including built-up felt, rubber, and GRP (glass reinforced plastic) systems. 
                    <br></br>
                    <br></br>
                    <img src={require('../images/grp_flat_roof.jpg')} width="367px" height="275" alt='grp_flat_roof' title="grp_flat_roof" loading="eager"/>    
                    <br></br>
                    <br></br>
                    We use only the highest quality materials and ensure that all work is completed to the highest standard.
                    <br></br>
                    <br></br>
                    <img src={require('../images/bitumen_roof.jpg')} width="367px" height="275" alt='bitumen_roof' title="bitumen_roof" loading="eager"/>    
                    <br></br>
                    <br></br>
                    All flat roof repairs and installations come with a guarantee.
                    <br></br>
                    <br></br>        
                    <img src={require('../images/flat_roof_repair.jpg')} width="367px" height="275" alt='flat_roof_repair' title="flat_roof_repair" loading="eager"/>    
                    <br></br>
                    <br></br>
                    Our team is dedicated to providing our customers with the best roofing services in the area, so you can trust that your flat roofing needs will be handled with the utmost care and expertise. Contact us today to schedule your appointment and receive a free estimate. We look forward to hearing from you.
                    <br></br>
                    <br></br>
                    <img src={require('../images/firestone_roof.jpg')} width="367px" height="275" alt='firestone_roof' title="firestone_roof" loading="eager"/>    
                    <br></br>
                    <br></br>
                </p>
            </section>
        </div>
    );
}

export default MiddleHome;