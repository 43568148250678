import { useState } from "react";

import {
    nameValidator,
    telValidator,
    emailValidator,
    messageValidator,
    passwordValidator,
    confirmPasswordValidator,
} from "./validators.js";

const touchErrors = errors => {
    return Object.entries(errors).reduce((acc, [field, fieldError]) => {
        acc[field] = {
            ...fieldError,
            dirty: true,
        };
        return acc;
    }, {});
};

export const useLoginFormValidator = form => {
    const [errors, setErrors] = useState({
        name: {
            dirty: false,
            error: false,
            message: "",
        },
        tel: {
            dirty: false,
            error: false,
            message: "",
        },
        email: {
            dirty: false,
            error: false,
            message: "",
        },
        message: {
            dirty: false,
            error: false,
            message: "",
        },
        password: {
            dirty: false,
            error: false,
            message: "",
        },
        confirmpassword: {
            dirty: false,
            error: false,
            message: "",
        },
        formSubmitIssue: {
            dirty: false,
            error: false,
            failure: "Please submit all required fields",
            success: "Form submitted",
        },
    });

    const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
        let isValid = true;

        var nextErrors = JSON.parse(JSON.stringify(errors));

        if (forceTouchErrors) {
            nextErrors = touchErrors(errors);
        }

        const { name, tel, email, message, password, confirmpassword, confirmsubmit } = form;

        if (nextErrors.name.dirty && (field ? field === "name" : true)) {
            const nameInput = nameValidator(name, form);
            nextErrors.name.error = !!nameInput;
            nextErrors.name.message = nameInput;
            if (!!nameInput) isValid = false;
        }

        if (nextErrors.tel.dirty && (field ? field === "tel" : true)) {
            const telInput = telValidator(tel, form);
            nextErrors.tel.error = !!telInput;
            nextErrors.tel.message = telInput;
            if (!!telInput) isValid = false;
        }

        if (nextErrors.email.dirty && (field ? field === "email" : true)) {
            const emailMessage = emailValidator(email, form);
            nextErrors.email.error = !!emailMessage;
            nextErrors.email.message = emailMessage;
            if (!!emailMessage) isValid = false;
        }

        if (nextErrors.message.dirty && (field ? field === "message" : true)) {
            const messageInput = messageValidator(message, form);
            nextErrors.message.error = !!messageInput;
            nextErrors.message.message = messageInput;
            if (!!messageInput) isValid = false;
        }

        if (nextErrors.password.dirty && (field ? field === "password" : true)) {
            const passwordMessage = passwordValidator(password, form);
            nextErrors.password.error = !!passwordMessage;
            nextErrors.password.message = passwordMessage;
            if (!!passwordMessage) isValid = false;
        }

        if (
            nextErrors.confirmpassword.dirty &&
            (field ? field === "confirmpassword" : true)
        ) {
            const confirmPasswordMessage = confirmPasswordValidator(
                confirmpassword,
                form
            );
            nextErrors.confirmpassword.error = !!confirmPasswordMessage;
            nextErrors.confirmpassword.message = confirmPasswordMessage;
            if (!!confirmPasswordMessage) isValid = false;
        }

        setErrors(nextErrors);

        return {
            isValid,
            errors: nextErrors,
        };
    };

    const onBlurField = e => {
        const field = e.target.name;
        const fieldError = errors[field];
        if (fieldError.dirty) return;

        const updatedErrors = {
            ...errors,
            [field]: {
                ...errors[field],
                dirty: true,
            },
        };

        validateForm({ form, field, errors: updatedErrors });
    };

    return {
        validateForm,
        onBlurField,
        errors,
    };
};



