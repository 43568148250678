import { useState } from "react";
import clsx from "clsx";
import styles from "./LoginForm.module.css";
import { useLoginFormValidator } from "./useLoginFormValidator";

const SignUpForm = props => {

    const [form, setForm] = useState({
        username: "",
        email: "",
        tel: "",
        firstname: "",
        lastname: "",
        dob: "",
        housenumber: "",
        street: "",
        conurbation: "",
        postcode: "",
        password: "",
        password2: "",
    });

    const { errors, validateForm, onBlurField } = useLoginFormValidator(form);

    const onUpdateField = e => {
        const field = e.target.name;
        const nextFormState = {
            ...form,
            [field]: e.target.value,
        };
        setForm(nextFormState);
        if (errors[field].dirty)
            validateForm({
                form: nextFormState,
                errors,
                field,
            });
    };

    function checkDetailsSuccess() {
        //console.log(".....");
        //Go to page, via backend??
    };

    function checkDetailsFail() {
        //console.log("FailedLogin");
    };

    //E-mail, password and tel must be unique
    const onSubmitForm = e => {

        e.preventDefault();

        fetch('/checkEmailPasswordTel', {

            method: "POST",

            body: JSON.stringify({
                username: form.username,
                email: form.email,
                tel: form.email,
                firstname: form.firstname,
                lastname: form.lastname,
                dob: form.dob,
                housenumber: form.housenumber,
                street: form.street,
                conurbation: form.conurbation,
                postcode: form.postcode,
                password: form.password,
                password2: form.password2,
            }),

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

        }).then(
            (response) => (response.json())

        ).then((response) => {

            if (response.status === 'success') {
                window.location.reload(true);
                checkDetailsSuccess();
            }

            else if (response.status === 'fail') {
                checkDetailsFail();
            }
        })
    };

    return (
        <form className={styles.form} onSubmit={onSubmitForm}>
            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Username</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="text"
                    placeholder="Please enter your username"
                    name="username"
                    value={form.username}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>E-mail</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="email"
                    placeholder="Please enter your E-mail"
                    name="email"
                    value={form.email}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Tel</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="tel"
                    placeholder="Please enter your tel"
                    name="tel"
                    value={form.tel}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>First Name</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="firstname"
                    placeholder="Please enter your first name"
                    name="firstname"
                    value={form.firstname}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Last Name</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="lastname"
                    placeholder="Please enter your last name"
                    name="lastname"
                    value={form.lastname}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Date of Birth</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="date"
                    placeholder="Please enter your date of birth"
                    name="dob"
                    value={form.dob}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>House Number</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="housenumber"
                    placeholder="Please enter your house number"
                    name="housenumber"
                    value={form.housenumber}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Street</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="street"
                    placeholder="Please enter your street"
                    name="street"
                    value={form.street}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Conurbation</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="conurbation"
                    placeholder="Please enter your conurbation"
                    name="conurbation"
                    value={form.conurbation}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Postcode</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="postcode"
                    placeholder="Please enter your postcode"
                    name="postcode"
                    value={form.postcode}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Password</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="password"
                    placeholder="Please create your password"
                    name="password"
                    value={form.password}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Confirm Password</label>
                <input
                    className={clsx(
                        styles.formField
                    )}
                    type="password"
                    placeholder="Please confirm your password"
                    name="password2"
                    value={form.password2}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
            </div>

            <button className={styles.formInputBtn} type="submit">Sign Up</button>

            <span className={styles.formInputLogin}>
                Already have an account? Login <a href='#'>here</a>
            </span>

        </form>
    );
}

export default SignUpForm







