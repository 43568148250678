import React from 'react';
import './Pages.css';
import HeroPointing from '../heroSections/HeroPointing';
import MiddlePointing from '../middleSections/MiddlePointing';
import Banner from '../banners/Banner';
import Footer from '../footers/Footer';

//helmet seo
import { Helmet } from "react-helmet-async";

function Pointing() {

    return (
        <>
            <Helmet>
                <title>Tunn Slating and Flat Roofing | Roofer Aberdeen | Pointing</title>
                <meta name="description" content="Roofers in Aberdeen, call us on 07394 387 027 for a free quote. All work guaranteed. Time served and qualified tradesmen. We do slating, roof tiling, flat roofs, pointing, cladding, ridges, skews and bird control. Roofer Aberdeen." />
                <link rel="canonical" href="/birdControl" />
            </Helmet>

            <HeroPointing />
            <MiddlePointing />
            <Banner />
            <Footer /> 
        </>
    );
}

export default Pointing;



