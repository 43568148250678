import React from 'react';
import '../footers/Footer.css';

function MiddlePointing() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Modern and Traditional Pointing 
                </p>

                <br></br>
                <br></br>

                <img src={require('../images/lime_pointing.jpeg')} width="367px" height="275px" alt='st_astier_lime' title="st_astier_lime" loading="eager"/> 
                
                <br></br>
                <br></br>

                <p className='footer-subscription-text'>
                    In addition to modern portland and hydrated lime renders and pointing, we provide our customers with traditional pointing methods including NHL (natural hydraulic lime) and lime putty for conservation projects.
                </p>

                <br></br>
                <br></br>

                <img src={require('../images/st_astier.jpg')} width="225px" height="225px" alt='st_astier_lime' title="st_astier_lime" loading="eager"/> 

            </section>

        </div>
    );
}

export default MiddlePointing;




