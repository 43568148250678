import React from 'react';
import '../footers/Footer.css';

function MiddleSkewsRidges() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Skews and Ridges
                </p>

                <p className='footer-subscription-text'>
                    Some of the most common causes of water ingress and damp is defective skews and ridges. Our team has the skills and experience necessary to provide you with top-quality workmanship and excellent customer service.
                    <br></br>
                    <br></br>
                    Skew pointing and joinery: This includes the renewal of membrane, re-slating and replacement of decking timber and rafters if necessary.
                    <br></br>
                    <br></br>
                    <img src={require('../images/skew_timber2.jpg')} width="301px" height="400px" alt='skew_timber' title="skew_timber" loading="eager"/>    
                    <br></br>
                    <br></br>        
                    <br></br>
                    Ridge work: We carry out work on all manner of ridges including cement fixed, dry fixed and zinc ridges.
                    <br></br>
                    <br></br>
                    <img src={require('../images/ridge_tiles.jpg')} width="435px" height="246px" alt='ridge_tiles' title="ridge_tiles" loading="eager"/>    
                    <br></br>
                    <br></br>
                    Our team is dedicated to providing our customers with the best roofing services in the area, so you can trust that your skew and ridge needs will be handled with the utmost care and expertise. Contact us today to schedule your appointment and receive a free estimate. We look forward to hearing from you.
                </p>
            </section>
        </div>
    );
}

export default MiddleSkewsRidges;




