import React from 'react';
import '../../App.css';
import { ButtonSignIn } from '../buttons//ButtonSignIn';
import { ButtonCallNow } from '../buttons//ButtonCallNow';
import './HeroSections.css';


function HeroContactUs() {

    return (

        <div className='hero-container'>

            <video src='/videos/beachfront.mp4' autoPlay loop muted />

            <img src={require('../images/logo2.jpg')} width="375px" height="198px" alt='logo' title="logo" loading="eager"/>    

            <h1>Contact Us</h1>

            <section>
                <div className='hero-btns'>
                    <ButtonCallNow className='btns' buttonStyle='btn--outline' buttonSize='btn--large'><a href="tel:07394 387027">CALL US</a></ButtonCallNow>
                </div>
            </section>

            <div className='hero-btns'>
                <ButtonSignIn className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>ADMIN LOG IN</ButtonSignIn>
            </div>

        </div>
    );
}

export default HeroContactUs;
