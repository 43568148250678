import React from 'react';
import '../footers/Footer.css';

function MiddleHome() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Roof Tiling and Slating
                </p>

                <p className='footer-subscription-text'>
                    We offer a wide range of tiling and slating services. Our team have the skills and experience necessary to provide you with top-quality workmanship and excellent customer service.
                    <br></br>
                    <br></br>
                    <img src={require('../images/welsh_slate.jpg')} width="367px" height="275" alt='welsh_slate' title="welsh_slate" loading="eager"/>    
                    <br></br>
                    <br></br>        
                    ROOF TILING
                    <br></br>
                    <br></br>        
                    We offer a comprehensive range of tiling services including new installations, repairs and replacements for both residential and commercial properties. We have experience working with all tile types including clay, concrete and natural stone. We can provide expert advice on the best option for your property.
                    <br></br>
                    <br></br>
                    <img src={require('../images/marley_tile_roof.jpg')} width="367px" height="275" alt='marley_tile_roof' title="marley_tile_roof" loading="eager"/>    
                    <br></br>
                    <br></br>        
                    SLATING SERVICES
                    <br></br>
                    <br></br>        
                    Our traditional slating services include new installations, repairs and replacements for both residential and commercial properties. We use only the highest quality materials and ensure that all of our work is carried out to the highest standard. Whether you need a new slate roof for your home or repairs to an existing one, we have the expertise to get the job done right.
                    <br></br>
                    <br></br>
                    <img src={require('../images/slater.jpg')} width="367px" height="275" alt='slater' title="slater" loading="eager"/>    
                    <br></br>
                    <br></br>
                    CONSERVATION ROOFING AND SLATING
                    <br></br>
                    <br></br>                
                    We retain a stock of Scottish/Ballachulish slates for repairs and installations allowing us to carry out repairs and installations on conservation projects throughout Scotland. We have a great deal of experience working with firms of architects and surveyors.
                    <br></br>
                    <br></br>        
                    <img src={require('../images/scottish_roof.jpg')} width="367px" height="275" alt='scottish_roof' title="scottish_roof" loading="eager"/>    
                    <br></br>
                    <br></br>
                    <img src={require('../images/burlington_slate.jpg')} width="367px" height="275" alt='burlington_slate' title="burlington_slate" loading="eager"/>    
                    <br></br>
                    <br></br>
                    Our team is dedicated to providing our customers with the best roofing services in the area, so you can trust that your slating and tiling needs will be handled with the utmost care and expertise. 
                    <br></br>
                    <br></br>        
                    Contact us today to schedule your appointment and receive a free estimate. We look forward to hearing from you.
                </p>
            </section>
        </div>
    );
}

export default MiddleHome;



