import React from 'react';
import './Banner.css';

function Banner() {

    return (

        <div class="container">

            <a href="https://www.ipaf.org/en/" target="blank" rel="noreferrer">
                <img alt='ipafImage' title="ipafImage" loading="eager" width = "225px" height = "225px" src={require('../images/ipaf.png')} /> </a>

            <a href="https://www.cityandguilds.com/" target="blank" rel="noreferrer">
                <img alt='CityAndGuilds' title="CityAndGuilds" loading="eager" width="225px" height="225px" src={require('../images/city.png')} /> </a>

        </div>
    );
};

export default Banner;


