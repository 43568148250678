import React from 'react';
import '../footers/Footer.css';

function MiddleHome() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Welcome to the Homepage of Tunn Construction.
                </p>

                <p className='footer-subscription-text'>
                    We are a full-service roofing, cladding and construction company in Aberdeen. 
                    <br></br>
                    <br></br>                        
                    Our team of City & Guilds qualified and trained operatives are experts in all aspects of roofing and cladding including traditional tile and slate roofing, flat roofing, cladding, repairs and maintenance as well as bird control.
                    <br></br>
                    <br></br>        
                    We have a great deal of experience in conservation roofing and the use of lime mortars, please see the relevant pages for these services.
                    <br></br>
                    <br></br>        
                    We provide our services to both commercial and domestic customers and are available 24/7 in emergencies. We also offer free estimates and surveys to help you get a better understanding of your roofing requirements.
                    <br></br>
                    <br></br>
                    Please take time to look through the services that we offer.
                    <br></br>
                    <br></br>        
                    Thank you for considering us. We look forward to working with you.
                </p>
                
            </section>
        </div>
    );
}

export default MiddleHome;



