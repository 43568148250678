import React from 'react';
import '../../App.css';
import { Button } from '../buttons//Button';
import { ButtonCallNow } from '../buttons//ButtonCallNow';
import './HeroSections.css';

function HeroRepairs() {

    return (

        <div className='hero-container'>

            <video src='/videos/roof-painting.mp4' autoPlay loop muted />

            <img src={require('../images/logo2.jpg')} width="375px" height="198px" alt='logo' title="logo" loading="eager"/>    

            <h1>Repairs and Maintenance</h1>

            <section>
                <div className='hero-btns'>
                    <ButtonCallNow className='btns' buttonStyle='btn--outline' buttonSize='btn--large'><a href="tel:07394 387027">CALL US</a></ButtonCallNow>
                </div>
            </section>

            <section>
                <div className='hero-btns'>
                    <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>CONTACT FORM</Button>
                </div>
            </section>

        </div>
    );
}

export default HeroRepairs;