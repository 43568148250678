import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

//Helmet
import { Helmet } from 'react-helmet-async';

//Import combined Navbar and Sidebar component
import Navbar from './components/NavbarSidebar/Navbar';

//Import pages
import Home from './components/pages/Home';
import PitchedRoofing from './components/pages/PitchedRoofing';
import FlatRoofing from './components/pages/FlatRoofing';
import Cladding from './components/pages/Cladding';
import Repairs from './components/pages/Repairs';
import SkewsRidges from './components/pages/RidgesAndSkews';
import Pointing from './components/pages/Pointing';
import BirdControl from './components/pages/BirdControl';
import ContactUs from './components/pages/ContactUs';
import SignUp from './components/pages/SignUp';
import SignIn from './components/pages/SignIn';

function App() {

    <Helmet>
        <title>Tunn Roofing and Construction | Roofer Aberdeen</title>
        <meta name="description" content="Roofers in Aberdeen, call us on 07394 387 027 for a free quote. All work guaranteed. Time served and qualified tradesmen. We do slating, roof tiling, flat roofs, pointing, cladding, ridges, skews and bird control. Roofer Aberdeen." />
    </Helmet>

    return (
        <>
            <Router>

                <Navbar />
                
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/pitchedRoofing' element={<PitchedRoofing />} />
                    <Route path='/flatRoofing' element={<FlatRoofing />} />
                    <Route path='/cladding' element={<Cladding />} />
                    <Route path='/repairs' element={<Repairs />} />
                    <Route path='/ridgesAndSkews' element={<SkewsRidges />} />
                    <Route path='/pointing' element={<Pointing />} />
                    <Route path='/birdControl' element={<BirdControl />} />
                    <Route path='/contactUs' element={<ContactUs />} />
                    <Route path='/signUp' element={<SignUp />} />
                    <Route path='/signIn' element={<SignIn />} />
                </Routes>

                {/* WhatsApp icon */}
                <a
                    href="https://wa.me/4407394387027"
                    class="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="fa fa-whatsapp whatsapp-icon"></i>
                </a>
            </Router>
        </>
    );
}

export default App;


