import { useState } from "react";
import clsx from "clsx";
import styles from "./LoginForm.module.css";
import { useLoginFormValidator } from "./useLoginFormValidator";

const SignInForm = props => {

    const [form, setForm] = useState({
        email: "",
        password: "",
        confirmpassword: "",
    });

    const { errors, validateForm, onBlurField } = useLoginFormValidator(form);

    const onUpdateField = e => {
        const field = e.target.name;
        const nextFormState = {
            ...form,
            [field]: e.target.value,
        };
        setForm(nextFormState);
        if (errors[field].dirty)
            validateForm({
                form: nextFormState,
                errors,
                field,
            });
    };

    function loginSuccess () {
        console.log(".....");
        //Go to page, via backend??
    };

    function loginFail() {
        console.log("FailedLogin");
    };

    const onSubmitForm = e => {

            e.preventDefault();

            fetch('/checkEmailAndPassword', {

                method: "POST",

                body: JSON.stringify({
                    email: form.email,
                    password: form.password
                }),

                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

            }).then(
                (response) => (response.json())

            ).then((response) => {

                if (response.status === 'success') {
                    window.location.reload(true);
                    loginSuccess();
                }

                else if (response.status === 'fail') {
                    loginFail();
                }
            })
    };

    return (
        <form className={styles.form} onSubmit={onSubmitForm}>
            <div className={styles.formGroup}>
                <label className={styles.formLabel}>E-mail</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.email.dirty && errors.email.error && styles.formFieldError
                    )}
                    type="text"
                    placeholder="Please enter your E-mail"
                    name="email"
                    value={form.email}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.email.dirty && errors.email.error ? (
                    <p className={styles.formFieldErrorMessage}>{errors.email.message}</p>
                ) : null}
            </div>
            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Password</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.password.dirty &&
                        errors.password.error &&
                        styles.formFieldError
                    )}
                    type="password"
                    placeholder="Please enter your password"
                    name="password"
                    value={form.password}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.password.dirty && errors.password.error ? (
                    <p className={styles.formFieldErrorMessage}>
                        {errors.password.message}
                    </p>
                ) : null}
            </div>
            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Confirm Password</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.confirmpassword.dirty &&
                        errors.confirmpassword.error &&
                        styles.formFieldError
                    )}
                    type="password"
                    placeholder="Please enter your password"
                    name="confirmpassword"
                    value={form.confirmpassword}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.confirmpassword.dirty && errors.confirmpassword.error ? (
                    <p className={styles.formFieldErrorMessage}>
                        {errors.confirmpassword.message}
                    </p>
                ) : null}
            </div>

            <button className={styles.formInputBtn} type="submit">Log In</button>

            <span className={styles.formInputLogin}>
                Forgotten your password? Click <a href='/changePassword'>here</a>
            </span>

        </form>
    );
};

export default SignInForm



