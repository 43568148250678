import React from 'react';
import '../footers/Footer.css';

function MiddleHome() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Bird Control
                </p>

                <p className='footer-subscription-text'>
                    We understand the importance of protecting your roof and property from damage caused by birds. That's why we offer a comprehensive range of bird control services to our customers across Scotland. Our team has the skills and experience necessary to provide you with top-quality workmanship and excellent customer service.
                    <br></br>
                    <br></br>
                    Bird proofing: This includes the installation of bird spikes, bird netting and bird wire to prevent birds from nesting on your roof.
                    <br></br>
                    <br></br>
                    <img src={require('../images/bird_net.jpg')} width="367px" height="275" alt='bird_net' title="bird_net" loading="eager"/>    
                    <br></br>
                    <br></br>        
                    <br></br>
                    Nest and guano removal: If the nesting season is over and all nests are abandoned, our team can remove the nest and any debris left behind. Being a protected species, we do not remove active gull nests.
                    <br></br>
                    <br></br>
                    <img src={require('../images/seagulls_nest.jpg')} width="367px" height="275" alt='nesting_birds' title="nesting_birds" loading="eager"/>    
                    <br></br>
                    <br></br>
                    Our team is dedicated to providing our customers with the best roofing services in the area, so you can trust that your bird control needs will be handled with the utmost care and expertise. Contact us today to schedule your appointment and receive a free estimate. We look forward to hearing from you.
                </p>
            </section>
        </div>
    );
}

export default MiddleHome;




