import React from 'react';
import { LogoNodejs } from 'react-ionicons'


export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        cName: 'nav-text'
    },
    {
        title: 'Roof Tiling and Slating',
        path: '/pitchedRoofing',
        cName: 'nav-text'
    },
    {
        title: 'Flat Roofing',
        path: '/flatRoofing',
        cName: 'nav-text'
    },
    {
        title: 'Cladding',
        path: '/cladding',
        cName: 'nav-text'
    },
    {
        title: 'Repairs and Maintenance',
        path: '/repairs',
        cName: 'nav-text'
    },
    {
        title: 'Ridges and Skews',
        path: '/ridgesAndSkews',
        cName: 'nav-text'
    },
    {
        title: 'Pointing',
        path: '/pointing',
        cName: 'nav-text'
    },
    {
        title: 'Bird Control',
        path: '/birdControl',
        cName: 'nav-text'
    },
    {
        title: 'Contact Us',
        path: '/contactUs',
        cName: 'nav-text'
    }
];