import React from 'react';
import '../footers/Footer.css';

function MiddleHome() {
    return (
        <div className='footer-container'>

            <section className='footer-subscription'>

                <p className='footer-subscription-heading'>
                    Repairs and Maintenance
                </p>

                <p className='footer-subscription-text'>
                    We understand the importance of keeping your roof in good condition. Our team of City & Guilds qualified tradesmen have the skills and experience necessary to provide you with top-quality repairs and an excellent customer service.
                    <br></br>
                    <br></br>
                    REPAIRS
                    <br></br>
                    <br></br>        
                    Our repair services include fixing leaks, replacing damaged slates/tiles, repairing flashings etc. Whether you have a flat or a pitched roof our team has the expertise to quickly identify the problem and provide a solution that will last. We use only the highest-quality materials and ensure that all of our work is carried out to the highest standard.
                    <br></br>
                    <br></br>
                    <img src={require('../images/slate_repair.jpg')} width="367px" height="275" alt='slate_repair' title="slate_repair" loading="eager"/>    
                    <br></br>
                    <br></br>
                    <img src={require('../images/flat_roof_repair.jpg')} width="367px" height="275" alt='flat_roof_repair' title="flat_roof_repair" loading="eager"/>    
                    <br></br>
                    <br></br>
                    MAINTENANCE
                    <br></br>
                    <br></br>        
                    Regular maintenance is key to prolonging the life of your roof and preventing costly repairs. Our maintenance services include inspecting your roof for potential issues, cleaning gutters, downspouts and removing debris. We also provide advice on how to maintain your roof and prevent problems in the future.
                    <br></br>
                    <br></br>
                    <img src={require('../images/roof_cleaning.jpg')} width="367px" height="275" alt='roof_cleaning' title="roof_cleaning" loading="eager"/>    
                    <br></br>
                    <br></br>
                    Our team is dedicated to providing our customers with the best roofing services in the area, so you can trust that your repair and maintenance needs will be handled with the utmost care and expertise. Contact us today to schedule your appointment and receive a free estimate. We look forward to hearing from you.
                </p>
            </section>
        </div>
    );
}

export default MiddleHome;



