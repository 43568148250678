import { useState } from "react";
import clsx from "clsx";
import styles from "./LoginForm.module.css";
import { useLoginFormValidator } from "./useLoginFormValidator";
import ReCAPTCHA from "react-google-recaptcha";

const Result = () => { return (<label className={styles.formLabelGreen}>Thanks for your message. A member of staff will be in touch within 24 hours.</label>) };

const ContactForm = props => {

    const [captchaCheck, setCaptchaCheck] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptchaCheck(true);
    }

    const [form, setForm] = useState({
        name: "",
        tel: "",
        email: "",
        message: "",
    });

    const [result, showResult] = useState(false);
    const { errors, validateForm, onBlurField } = useLoginFormValidator(form);
    const [fName, setFName] = useState(false);
    const [fTel, setFTel] = useState(false);
    const [fEmail, setFEmail] = useState(false);
    const [fMessage, setFMessage] = useState(false);

    const onUpdateField = e => {

        setFName(e.target.value);
        setFTel(e.target.value);
        setFEmail(e.target.value);
        setFMessage(e.target.value);

        const field = e.target.name;
        const nextFormState = {
            ...form,
            [field]: e.target.value,
        };
        setForm(nextFormState);
        if (errors[field].dirty)
            validateForm({
                form: nextFormState,
                errors,
                field,
            });
    };

    const onSubmitForm = e => {

        e.preventDefault();

        fetch('/contactForm', {

            method: "POST",

            body: JSON.stringify({
                name: form.name,
                tel: form.tel,
                email: form.email,
                message: form.message,
            }),

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },

        }).then(
            (response) => (response.json())

        ).then((response) => {

            if (response.status === 'success') {

                showResult(true);

                setTimeout(() => {
                    window.location.reload(true);
                }, 3000)
            }
        })
    };

    const validate = () => {
        if (captchaCheck === true && fName.length > 0 && fTel.length > 5 && fEmail.length > 1 && fMessage.length > 25) {
            return true;
        }
    };

    return (

        <form className={styles.form} onSubmit={onSubmitForm}>
            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Name</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.name.dirty && errors.name.error && styles.formFieldError
                    )}
                    type="text"
                    placeholder="Please enter your name"
                    name="name"
                    value={form.name}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.name.dirty && errors.name.error ? (
                    <p className={styles.formFieldErrorMessage}>{errors.name.message}</p>
                ) : null}
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Tel</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.tel.dirty && errors.tel.error && styles.formFieldError
                    )}
                    type="text"
                    placeholder="Please enter your tel no"
                    name="tel"
                    value={form.tel}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.tel.dirty && errors.tel.error ? (
                    <p className={styles.formFieldErrorMessage}>{errors.tel.message}</p>
                ) : null}
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>E-mail</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.email.dirty && errors.email.error && styles.formFieldError
                    )}
                    type="text"
                    placeholder="Please enter your E-mail"
                    name="email"
                    value={form.email}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.email.dirty && errors.email.error ? (
                    <p className={styles.formFieldErrorMessage}>{errors.email.message}</p>
                ) : null}
            </div>

            <div className={styles.formGroup}>
                <label className={styles.formLabel}>Message</label>
                <input
                    className={clsx(
                        styles.formField,
                        errors.message.dirty && errors.message.error && styles.formFieldError
                    )}
                    type="text"
                    placeholder="Please enter your message"
                    name="message"
                    value={form.message}
                    onChange={onUpdateField}
                    onBlur={onBlurField}
                />
                {errors.message.dirty && errors.message.error ? (
                    <p className={styles.formFieldErrorMessage}>{errors.message.message}</p>
                ) : null}

            </div>

            <div>
                <ReCAPTCHA
                    theme="dark"
                    sitekey="6LeZJfckAAAAALJ-Gof1vocgFREK_8XVKrsMss_1"
                    onChange={onChange}
                />
            </div>

            <div>
                <button className={styles.formInputBtn} type="submit" disabled={!validate()}>Send Message</button>
            </div>

            <div>
                {(errors.name.dirty && errors.name.error) || (errors.tel.dirty && errors.tel.error) || (errors.email.dirty && errors.email.error || (errors.message.dirty && errors.message.error)) ? (
                    <p className={styles.formFieldErrorMessage}>{errors.formSubmitIssue.failure}</p>
                ) : null}
            </div>

            <div>{result ? <Result /> : null}</div>

        </form>
    )
}

export default ContactForm;


