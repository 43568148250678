import React from 'react';
import './Pages.css';
import HeroSignUp from '../heroSections/HeroSignUp';
import Banner from '../banners/Banner';
import Footer from '../footers/Footer';
import SignUpForm from '../forms/signUpForm';
    
//helmet seo
import { Helmet } from "react-helmet-async";


function SignUp() {

    return (
        <>
            <Helmet>
                <title>Tunn Slating and Flat Roofing | Roofer Aberdeen | Sign Up</title>
                <meta name="description" content="Roofers in Aberdeen, call us on 07394 387 027 for a free quote. All work guaranteed. Time served and qualified tradesmen. We do slating, roof tiling, flat roofs, pointing, cladding, ridges, skews and bird control. Roofer Aberdeen." />
                <link rel="canonical" href="/signUp" />
            </Helmet>

            <HeroSignUp />
            <SignUpForm />
            <Banner />
            <Footer />
        </>
    );
}

export default SignUp;



