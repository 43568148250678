import React from 'react';
import './Pages.css';
import HeroContactUs from '../heroSections/HeroContactUs';
import MiddleContactUs from '../middleSections/MiddleContactUs';
import Banner from '../banners/Banner';
import Footer from '../footers/Footer';
import ContactForm from '../forms/ContactForm';

//helmet seo
import { Helmet } from "react-helmet-async";

function ContactUs() {

    return (
        <>
            <Helmet>
                <title>Tunn Slating and Flat Roofing | Roofer Aberdeen | Contact Us</title>
                <meta name="description" content="Roofers in Aberdeen, call us on 07394 387 027 for a free quote. All work guaranteed. Time served and qualified tradesmen. We do slating, roof tiling, flat roofs, pointing, cladding, ridges, skews and bird control. Roofer Aberdeen." />
                <link rel="canonical" href="/contactUs" />
            </Helmet>

            <HeroContactUs />
            <MiddleContactUs />
            <ContactForm />
            <Banner />
            <Footer />
        </>
    );
}

export default ContactUs;



