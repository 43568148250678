import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';

import './Navbar.css';

import {NavLink} from './NavbarElements';

const Navbar = () => {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className='navbar'>

                    <div>
                        <Link to='#' className='menu-bars'>
                            <FaIcons.FaBars onClick={showSidebar} />
                        </Link>
                    </div>

                    <div className = 'navbar_sub'>
                        <NavLink to='/' activeStyle>
                            Home
                        </NavLink>
                        <NavLink to='/pitchedRoofing' activeStyle>
                            Roof Tiling and Slating
                        </NavLink>
                        <NavLink to='/flatRoofing' activeStyle>
                            Flat Roofing
                        </NavLink>
                        <NavLink to='/cladding' activeStyle>
                            Cladding
                        </NavLink>
                        <NavLink to='/repairs' activeStyle>
                            Repairs and Maintenance
                        </NavLink>
                        <NavLink to='/ridgesAndSkews' activeStyle>
                            Ridges and Skews
                        </NavLink>
                        <NavLink to='/pointing' activeStyle>
                            Pointing
                        </NavLink>3
                        <NavLink to='/birdControl' activeStyle>
                            Bird Control
                        </NavLink>
                        <NavLink to='/contactUs' activeStyle>
                            Contact Us
                        </NavLink>
                    </div>

                </div>

                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                            <Link to='#' className='menu-bars'>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;

