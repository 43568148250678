import React from 'react';
import './Pages.css';
import HeroSkewsRidges from '../heroSections/HeroSkewsRidges';
import MiddleSkewsRidges from '../middleSections/MiddleSkewsRidges';
import Banner from '../banners/Banner';
import Footer from '../footers/Footer';

//helmet seo
import { Helmet } from "react-helmet-async";

function SkewsRidges() {

    return (
        <>
            <Helmet>
                <title>Tunn Slating and Flat Roofing | Roofer Aberdeen | Ridges and Skews</title>
                <meta name="description" content="Roofers in Aberdeen, call us on 07394 387 027 for a free quote. All work guaranteed. Time served and qualified tradesmen. We do slating, roof tiling, flat roofs, pointing, cladding, ridges, skews and bird control. Roofer Aberdeen." />
                <link rel="canonical" href="/ridgesAndSkews" />
            </Helmet>

            <HeroSkewsRidges />
            <MiddleSkewsRidges />
            <Banner />
            <Footer /> 
        </>
    );
}

export default SkewsRidges;



