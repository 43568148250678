import React from 'react';
import './Pages.css';
import HeroSignIn from '../heroSections/HeroSignIn';
import Banner from '../banners/Banner';
import Footer from '../footers/Footer';
import SignInForm from '../forms/signInForm'

//helmet seo
import { Helmet } from "react-helmet-async";

function SignIn() {

    return (
        <>
            <Helmet>
                <title>Tunn Slating and Flat Roofing | Roofer Aberdeen | Sign In</title>
                <meta name="description" content="Roofers in Aberdeen, call us on 07394 387 027 for a free quote. All work guaranteed. Time served and qualified tradesmen. We do slating, roof tiling, flat roofs, pointing, cladding, ridges, skews and bird control. Roofer Aberdeen." />
                <link rel="canonical" href="/signIn" />
            </Helmet>

            <HeroSignIn />
            <SignInForm />
            <Banner />
            <Footer />
        </>
    );
}

export default SignIn;



