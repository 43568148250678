import React from 'react';
import '../footers/Footer.css';

function MiddleContactUs() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Please leave us a message and a member of staff will get back to you within 24 hours.
                </p>
            </section>
        </div>
    );
}

export default MiddleContactUs;



