var nameValidatorFLAG = false;
var telValidatorFLAG = false;
var emailValidatorFLAG = false;
var messageValidatorFLAG = false;

export const nameValidator = name => {
    if (!name) {
        nameValidatorFLAG = false;
        return "Name is required";
    } else {
        nameValidatorFLAG = true;
        contactFormValidator();
        return "";
    }
};

export const telValidator = tel => {
    if (!tel) {
        telValidatorFLAG = false;
        return "Tel is required";
    } else if (tel.length <= 5) {
        telValidatorFLAG = false;
        return "Please enter a valid tel no";
    } else if (tel.length > 5) {
        telValidatorFLAG = true;
        contactFormValidator();
        return "";
    }
};

export const emailValidator = email => {
    if (!email) {
        emailValidatorFLAG = false;
        return "E-mail is required";
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
        emailValidatorFLAG = false;
        return "Incorrect email format";
    } else {
        emailValidatorFLAG = true;
        contactFormValidator();
        return "";
    }
};

export const messageValidator = message => {
    if (!message) {
        messageValidatorFLAG = false;
        return "Message is required";
    } else if (message.length > 1 && message.length < 25) {
        messageValidatorFLAG = false;
        return "Message must be 25 characters or more";
    } else if (message.length > 25) {
        messageValidatorFLAG = true;
        contactFormValidator();
        return "";
    }
};

export const passwordValidator = password => {
    if (!password) {
        return "Password is required";
    } else if (password.length < 8) {
        return "Password must have a minimum 8 characters";
    }
    return "";
};

export const confirmPasswordValidator = (confirmpassword, form) => {
    if (!confirmpassword) {
        return "Confirm password is required";
    } else if (confirmpassword.length < 8) {
        return "Confirm password must have a minimum 8 characters";
    } else if (confirmpassword !== form.password) {
        return "Passwords do not match";
    }
    return "";
};

export const contactFormValidator = () => {
    if (nameValidatorFLAG === true && telValidatorFLAG === true && emailValidatorFLAG === true && messageValidatorFLAG === true) {
        return true;
    } else {
        return false;
    }
};



